<template>
  <div>
    <b-overlay v-if="loaded" rounded="sm">
      <b-card no-body class="mb-0">
        <div class="m-2">
          <b-row>
            <b-col md="3">
              <validation-provider #default="validationContext" :name="$t('Search')"
                rules="min:3">
                <b-form-group :label="$t('Name of the Expert')">
                  <b-form-input v-model="search" class="d-inline-block mr-1" size="sm" :placeholder="$t('Search...')" />
                </b-form-group>
                <small class="text-danger">{{ validationContext.errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col md="3">
              <b-form-group :label="$t('Expert Topics')" v-if="EXPERTS_TOPICS">
                <v-select :placeholder="$t('filter by Expert Topics')" v-model="filter.experttopics"
                  :options="EXPERTS_TOPICS" name="experttopics" label="name" multiple :close-on-select="false"
                  class="select-size-sm" />
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group :label="$t('Target Groups')" v-if="TARGET_GROUPS">
                <v-select :placeholder="$t('filter by Target Groups')" v-model="filter.targetgroups" name="targetgroups"
                  :options="TARGET_GROUPS" label="name" multiple :close-on-select="false" class="select-size-sm" />
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group :label="$t('Languages')" v-if="LANGUAGES">
                <v-select :placeholder="$t('filter by Language')" v-model="filter.languages" name="languages"
                  :options="LANGUAGES" multiple label="name" :close-on-select="false" class="select-size-sm" />
              </b-form-group>
            </b-col>
          </b-row>
        </div>
      </b-card>
      <b-row class="mt-2" v-if="!searchRequest">
        <b-col md="3" class="mt-2" v-for="expert in EXPERTS" :key="experts.id">
          <expert-card :expert="expert" />
        </b-col>
      </b-row>
      <div class="d-flex justify-content-center mb-1" v-if="searchRequest">
        <b-spinner label="Loading..." />
      </div>
      <div class="d-flex justify-content-center">
        <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="success" type="submit" size="sm" @click="loadMore"
          :class="[(EXPERTS_PAGINATION.current_page == EXPERTS_PAGINATION.last_page)?'d-none':'']">
          <b-spinner small v-if="loadMoreData" />
          {{ $t('Load more entries') }}
        </b-button>
      </div>
    </b-overlay>

  </div>


</template>

<script>
import {
  BAvatar,
  BBadge,
  BButton,
  BCard,
  BCardBody,
  BCardFooter,
  BCardGroup,
  BCardText,
  BCardTitle,
  BCarousel,
  BCarouselSlide,
  BCol,
  BFormGroup,
  BFormInput,
  BFormRating,
  BFormSelect,
  BOverlay,
  BRow,
  VBTooltip,
  BSpinner
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import {mapActions, mapGetters} from "vuex";
import ExpertCard from "@/views/experts/ExpertCard";
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required, min } from '@validations'

export default {
  components: {
    ExpertCard,
    BRow,
    BCol,
    BCardGroup,
    BCard,
    BCardText,
    BCardTitle,
    BFormSelect,
    BCardFooter,
    BCardBody,
    BFormGroup,
    BAvatar,
    BFormRating,
    BBadge,
    BButton,
    BCarousel,
    BCarouselSlide,
    BFormInput,
    vSelect,
    BOverlay,
    VBTooltip,
    ValidationProvider,
    ValidationObserver,
    required, min,
    BSpinner
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      loaded: false,
      loadMoreData: false,
      searchRequest: false,
      slide: 0,
      sliding: null,
      interval: 4000,
      search: '',
      experts: [],
      filter: {
        targetgroups: [],
        experttopics: [],
        languages: [],
      },
    }
  },

  computed: {
    ...mapGetters(['EXPERTS', 'EXPERTS_TOPICS', 'LANGUAGES', 'TARGET_GROUPS','EXPERTS_PAGINATION']),
  },
  watch: {
    // whenever question changes, this function will run
    search(newSearchQuery, oldSearchQuery) {
      if ((newSearchQuery != oldSearchQuery) && !this.searchRequest && newSearchQuery.length >= 3) {
        this.searchRequest = true
        this.getExperts()
      } else {
        if (!newSearchQuery.length) {
          this.searchRequest = true
          this.getExperts()
        }
      }
    },
    'filter.languages'(newEvenLang, oldEventLang) {
      if ((newEvenLang != oldEventLang) && !this.searchRequest) {
        this.searchRequest = true
        this.getExperts()
      }
    },
    'filter.targetgroups'(newTargetGroups, oldTargetGroups) {
      if ((newTargetGroups != oldTargetGroups) && !this.searchRequest) {
        this.searchRequest = true
        this.getExperts()
      }
    },
    'filter.experttopics'(newExpertTopics, oldExpertTopics) {
      if ((newExpertTopics != oldExpertTopics) && !this.searchRequest) {
        this.searchRequest = true
        this.getExperts()
      }
    },
  },

  methods: {
    ...mapActions(['GET_EXPERTS', 'GET_EXPERTS_TOPICS', 'GET_LANGUAGES','GET_TARGET_GROUPS']),

    loadMore() {
      this.loadMoreData = true
      this.getExperts(this.page + 1)
    },
    getExperts(page = 1){
      this.page = page
      let searchParams = {
        search: this.search,
        languages: this.filter.languages,
        targetgroups: this.filter.targetgroups,
        experttopics: this.filter.experttopics
      }
      this.GET_EXPERTS({ page, searchParams }).then(() => {
        this.loadMoreData = false
        this.searchRequest = false
        this.GET_EXPERTS_TOPICS()
        this.GET_LANGUAGES()
        this.GET_TARGET_GROUPS()
        this.loaded = true
      }
      )
    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null
    },

  },
  mounted() {
    this.getExperts();
  },
}
</script>
<style lang="scss">
.carousel-interval {
  no-wrap: true
}

.carousel-indicators li {
  width: 5px;
  height: 5px;
  border-radius: 100%;
}

.profile-badge {
  margin: 5px;
}

.search-button {
  margin-top: 28px;
  margin-left: 10px;
}

.expert-avatar {
  border: 3px solid grey;
}

.rating-expert {
  margin-top: 3px;
}

.usericon {
  margin-right: 5px;
}

.star {
  color: orange;
  margin-bottom: 2px;
}

.result-rating {
  margin-left: 3px;
  margin-top: 5px;
}

@import '@core/scss/vue/libs/vue-select.scss';
</style>

