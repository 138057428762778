<template>
  <div class="expert_card h-100 d-flex flex-column">
    <div class="expert_card__header  py-2 d-flex flex-column align-items-center">
      <b-avatar
          v-if="expert.avatar"
          variant="light-secondary"
          :to="{ name: 'expert-profile', params: {expertid: expert.expert.id}}"
          class="expert-avatar"
          size="90"
          :src="expert.avatar.url"/>
      <div v-if="!expert.avatar" class="empty_user_avatar">
        <feather-icon icon="UserIcon" size="60"/>
      </div>
      <h4 class="card-text expert_name pt-1">{{ expert.firstname }} {{ expert.name }}</h4>

      <b-button
          class="d-flex align-items-center expert_profile_link"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          size="sm"
          :to="{ name: 'expert-profile', params: {expertid: expert.expert.id}}"
          variant="primary">
        <span>  <feather-icon icon="UserIcon" size="10"/> </span>

        {{ $t('Show Profile') }}
      </b-button>


    </div>
    <div class="expert_card__body h-100 d-flex flex-column">

      <div class="expert_card__body__info">
        <div class="events_count">
          <p class="events_count_txt">
            {{ expert.expert.activeevents.length }}
          </p>
          <p class="events_title">
            {{ $t('Events') }}
          </p>
        </div>
        <div class="count_separator"></div>

        <div class="events_count">
          <p class="events_count_txt">
            {{ expert.expert.videonuggets.length }}
          </p>
          <p class="events_title">
            {{ $t('Video Nuggets') }}
          </p>
        </div>
      </div>
      <div class="expert_card__body__topics">
        <p class="topictitle"> {{ $t('Experttopics') }}</p>
        <div class="topics_list" v-if="expert.experttopics.length">
          <b-badge
              pill
              class="profile-badge"
              v-for="experttopic in expert.experttopics"
              :key="'experttopic_' + experttopic.id">
            {{ experttopic.name }}
          </b-badge>
        </div>

        <div class="topics_list d-flex justify-content-center" v-else>
          <b-badge
              pill
              class="profile-badge">
            {{ $t('The Expert didn’t choose any Topics yet') }}
          </b-badge>
        </div>


      </div>
      <div class="expert_card__body__decription h-100">
        <p class="decription_txt"> {{ expert.slogan }}</p>
      </div>
      <div class="expert_card__footer">
        <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="success"
            class="w-100 py-2"
            :to="{ name: 'experts-details', params: {expertid: expert.expert.id}}">
          {{ $t('Show Events') }}
        </b-button>

      </div>

    </div>
  </div>
</template>

<script>
import {
  BAvatar, BBadge, BButton, VBTooltip,

} from 'bootstrap-vue'
import Ripple from "vue-ripple-directive";


export default {
  name: "ExpertCard",
  props: ['expert'],

  components: {
    BButton,
    VBTooltip,
    BAvatar,
    BBadge,

  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
}
</script>

<style scoped lang="scss">

.expert_card {
  background: #D9D9D9;
  border-radius: 6px;
  padding: 10px;


  &__header {
    background: #EAE9E9;
    border-radius: 6px;

    .empty_user_avatar {
      width: 90px;
      height: 90px;
      border-radius: 50%;
      border: 6px solid #D9D9D9;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .expert_profile_link {
      margin-top: 10px;
      font-size: 10px;
      background-color: rgb(126, 126, 126) !important;
      border: none;
      padding: 0.386rem 0.486rem;

      span {
        margin-right: 5px;
      }
    }

    .expert-avatar {
      border: 6px solid #D9D9D9;
    }

    .expert_name {
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      text-align: center;
      letter-spacing: 0.4px;
      color: #989292;
    }

  }

  &__body {
    &__topics {
      margin: 10px 0;
      background: #F2F2F2;
      border-radius: 6px;
      padding: 10px 20px 20px 20px;


      .topics_list {
        .profile-badge {
          background: rgba(164, 126, 78, 0.15);
          color: #82868B;

          white-space: break-spaces;
          line-height: 13px;
        }
      }

      .topictitle {
        font-weight: 700;
        font-size: 14px;
        line-height: 17px;
        text-align: center;
        letter-spacing: 0.4px;

        opacity: 0.5;
      }
    }

    &__decription {
      background: #EAE9E9;
      padding: 20px;
      padding-bottom: 25px;
      border-radius: 6px;

      .decription_txt {
        font-weight: 700;
        font-size: 14px;
        line-height: 15px;
        text-align: center;
        letter-spacing: 0.4px;

        opacity: 0.5;


      }
    }

    &__info {
      margin-top: 10px;
      background: #EAE9E9;
      padding: 15px;
      border-radius: 6px;
      display: flex;
      justify-content: center;
      position: relative;
      align-items: center;

      &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 43px;
        background: linear-gradient(180deg, rgba(237, 237, 237, 0) 46.46%, #DBDBDB 93.36%);
        transform: rotate(180deg);
      }

      .count_separator {
        background: rgba(0, 0, 0, 0.26);
        width: 1px;
        height: 50px;

      }

      .events_count {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 50%;


        .events_count_txt {
          font-style: normal;
          font-weight: 700;
          font-size: 21px;
          letter-spacing: 0.4px;

          margin-bottom: 0;

        }

        .events_title {
          padding-top: 10px;
          font-weight: 700;
          font-size: 13px;
          line-height: 16px;
          text-align: center;
          letter-spacing: 0.4px;

          opacity: 0.5;
          margin-bottom: 0;
        }
      }

    }
  }

  &__footer {
    margin-top: -5px;
  }
}
.dark-layout{
  .expert_card{
    background: #2e2e30;
    .expert_card__body__info,
    .expert_card__body__topics,
    .expert_card__body__decription,
    .expert_card__header{
      *{
        opacity: 1;
      }

      background:  #444447;
      &:after{
        display: none;
      }
    }
    .expert_card__body__info .count_separator{
      background: #b4b7bd;
    }
  }
}

.dark-layout{
  .profile-badge{
    color: #b4b7bd !important;
  }
  .expert_card__header .expert_name{
    color: #b4b7bd;
  }
}


</style>
